@font-face {
  font-family: YekanBakh;
  src: url(../src/assets/fonts/IRANSansWeb.woff2) format("woff2"),
    url(../src/assets/fonts/IRANSansWeb.woff2) format("woff");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}

input , button {
  font-family: YekanBakh;
}
.book-viewer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.book-page {
  max-width: 100%;
  /* height: 100vh; */
  /* max-height: 100vh;
  object-fit: cover; */
  height: 100dvh;

}

.page-indicator {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.page-jump-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.page-jump-icon:hover {
  transform: scale(1.1);
}

.page-input-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-input-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 245px;
}

.page-input-container input {
  width: 150px;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #818181;
}



.page-input-container button {
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #298e46;
  color: #fff;
  border: 0;
  border-radius: 7px;
}

.imgS {
  width: 25px;
}

.cancel-pop {
  display: flex;
    margin-bottom: 10px;
}






.navigation-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.navigation-button img {
  width: 25px;
  height: 25px;
}

.navigation-button:hover {
  transform: scale(1.1);
}

.left-button {
  left: 10px;
}

.right-button {
  right: 10px;
}

@media (max-width:726px) {
  .prev-page-button , .next-page-button {
    display: none;
  }
  
}

@media (min-width: 726px) {
  .prev-page-button,
  .next-page-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      border: none;
      border-radius: 15px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      transition: transform 0.3s, background-color 0.3s;
  }

  .prev-page-button {
      left: 20px;
  }

  .next-page-button {
      right: 20px;
  }

  .prev-page-button:hover,
  .next-page-button:hover {
      transform: translateY(-50%) scale(1.1);
      background-color: rgba(0, 0, 0, 0.8);
  }
}


